import { React, useState } from "react";
import { Grid } from "@material-ui/core";
import { useShopify } from "../hooks";
import Product from "./Product";
import Footer from "./Footer";
import NewNav from "./NewNav";
import LatestVideos from "./LatestVideos";

const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 4;
    if (width < 1280) return 6;
    return 8;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item xs={6} md={4} lg={3} key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const Accessories = (props) => {
  const { products } = useShopify();

  return (
    <>
      <NewNav />
      <div>
        <div className="App__header">
          <div className="carousel-image">
            <img src="././Assets/banner.jpg" alt="Mithzan Merch Store" />
          </div>
        </div>

        <div className="App__title">
          <h1 className="App__title-text">Accessories</h1>
        </div>
      </div>

      <div className="Products-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={false} md={1} xl={2} />
          <Grid item xs={12} md={10} xl={8}>
            <div className="Products-wrapper__inner">
              <Grid container spacing={3}>
                {products && products.length > 0 ? (
                  products
                    .filter(function (product) {
                      for (var misc in products) {
                        if (
                          product.productType === "Hat" ||
                          product.productType === "Phone Case" ||
                          product.productType === "Mug" ||
                          product.handle.includes("plushie") ||
                          product.handle.includes("shorts") ||
                          product.title.includes("Socks") ||
                          product.title.includes("Blanket") ||
                          product.title.includes("Sticker") ||
                          product.title.includes("Poster")
                        ) {
                          return misc;
                        }
                      }
                    })
                    .map((misc, i) => (
                      <Grid item xs={6} md={4} lg={3} key={i}>
                        <Product product={misc} history={props.history} />
                      </Grid>
                    ))
                ) : (
                  <Placeholder />
                )}
              </Grid>
            </div>
            <Grid item xs={false} md={2} />
          </Grid>
        </Grid>
      </div>

      <Footer />
    </>
  );
};

export default Accessories;
